import { useState, useEffect } from "react";
import "react-day-picker/dist/style.css";
import { createEvent } from "../../api/events";
import Calendar from "../Calendar";
import Input from "../Input";
import Radio from "../Radio";
import Confirmation from "./Confirmation";
import styles from "./style.module.scss";
import useDebounce from "../../common/useDebounce";

const requiredFields = ["country", "city", "eventType", "eventDate"];

const defaultState = {
  country: "",
  city: "",
  eventName: "",
  organisationName: "",
  street: "",
  link: "",
  eventType: "",
  eventDate: "",
  eventTime: "",
  comments: "",
};

const AddEventForm = ({
  eventTypes,
  onSearchUpdate,
  currentCoordinates,
  onRadioButtonUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onOpenBtnClick = () => setIsOpen(!isOpen);

  const [form, updateForm] = useState(defaultState);
  // const { country, city, street } = form;

  const onFormChange = (fieldName, value) => {
    updateForm((form) => ({
      ...form,
      [fieldName]: value,
    }));
  };

  const country = useDebounce(form.country);
  const city = useDebounce(form.city);
  const street = useDebounce(form.street);

  useEffect(() => {
    if (country || city || street) {
      onSearchUpdate({ country, city, street });
    } else {
      onSearchUpdate(null);
    }
  }, [country, city, street, onSearchUpdate]);

  const eventType = form.eventType;

  useEffect(() => {
    onRadioButtonUpdate(eventType);
  }, [eventType, onRadioButtonUpdate]);

  const isAllFieldsFilled = requiredFields.every((field) => !!form[field]);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    const event = {
      country: form.country,
      city: form.city,
      street: form.street,
      link: form.link,
      eventName: form.eventName,
      organisationName: form.organisationName,
      eventType: form.eventType,
      comments: form.comments,
      eventDate: form.eventDate,
      eventTime: form.eventTime,
      latitude: (currentCoordinates && currentCoordinates.lat) || 0.01,
      longitude: (currentCoordinates && currentCoordinates.lon) || 0.02,
    };

    try {
      await createEvent(event);

      setIsSuccess(true);
      updateForm(defaultState);
    } catch (error) {
      setIsError(true);
      console.error("Error submitting form", error);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setIsSuccess(false);
    onSearchUpdate(null);
    onRadioButtonUpdate(null);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <button
          className={`${styles.openBtn} ${isOpen ? styles.closeBtn : ""}`}
          onClick={onOpenBtnClick}
          title="Open event submit form"
        />
        <span className={styles.address}>Open the form to add your event</span>
      </div>

      {isOpen ? (
        isSuccess ? (
          <Confirmation onClose={onClose} />
        ) : (
          <form
            id={styles.formCreateEvent}
            action=""
            method=""
            onSubmit={onSubmit}
            className={isOpen ? "" : styles.close}
          >
            <h1 className={styles.formTitle}>create event</h1>
            <div className={styles.formBody}>
              <Input
                label="country"
                required
                value={form.country}
                onChange={(e) => onFormChange("country", e)}
              />
              <Input
                label="city"
                required
                value={form.city}
                onChange={(e) => onFormChange("city", e)}
              />
              <Input
                label="street"
                value={form.street}
                onChange={(e) => onFormChange("street", e)}
              />
              <Input
                label="link"
                value={form.link}
                onChange={(e) => onFormChange("link", e)}
              />
              <Input
                label="event name"
                value={form.eventName}
                onChange={(e) => onFormChange("eventName", e)}
              />

              <Input
                label="organisation name"
                value={form.organisationName}
                onChange={(e) => onFormChange("organisationName", e)}
              />

              <fieldset id="eventType" className={styles.radioButtons}>
                <legend className={styles.title}>event type*</legend>
                {eventTypes.map((eventType) => (
                  <Radio
                    key={eventType.name}
                    value={eventType.value}
                    name="eventType"
                    label={eventType.name}
                    className={eventType.className}
                    onChange={(e) => onFormChange("eventType", e.target.value)}
                  />
                ))}
              </fieldset>

              <Input
                multiline
                label="comments"
                value={form.comments}
                onChange={(e) => onFormChange("comments", e)}
              />
              <Calendar
                onDateChange={(dt) => onFormChange("eventDate", dt.toString())}
                onTimeChange={(e) => onFormChange("eventTime", e)}
              />
              {isError ? (
                <div className={styles.formError} role="alert">
                  Couldn‘t submit event information. Maybe try again later?
                </div>
              ) : null}
              <input
                disabled={!isAllFieldsFilled}
                type="submit"
                value="publish"
                className={styles.submit}
              />
            </div>
          </form>
        )
      ) : null}
    </div>
  );
};

export default AddEventForm;
