import { useState, useEffect } from "react";
import styles from "./style.module.scss";

const Input = ({
  label,
  required,
  errorText,
  onChange,
  onClick,
  value,
  disabled = false,
  inputClass,
  labelClass,
  multiline = false,
}) => {
  const [localValue, setLocalValue] = useState(value);
  const [isStartType, setIsStartType] = useState(false);
  const [isError, setIsError] = useState(false);

  const onChangeLocalValue = (event) => {
    if (!isStartType) setIsStartType(true);
    if (isError) checkField();

    setLocalValue(event.target.value);

    if (!onChange) return;
    onChange(event.target.value);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const labelWithRequired = `${label}${required ? "*" : ""}`;

  const checkField = () => {
    if (!localValue.trim() && required) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  return (
    <div className={`${styles.wrapper} ${isError ? styles.error : ""}`} onClick={onClick}>
      {multiline ? (
        <textarea
          placeholder={labelWithRequired}
          name={label}
          id={label}
          className={`${styles.input} ${styles.textarea} ${inputClass}`}
          value={onChange && value ? value : localValue}
          onChange={onChangeLocalValue}
          onBlur={checkField}
          disabled={disabled}
        />
      ) : (
        <input
          type="text"
          placeholder={labelWithRequired}
          name={label}
          id={label}
          className={`${styles.input} ${inputClass}`}
          value={onChange && value ? value : localValue}
          onChange={onChangeLocalValue}
          onBlur={checkField}
          disabled={disabled}
        />
      )}
      <label htmlFor={label} className={`${styles.label} ${labelClass}`}>
        {labelWithRequired}
      </label>
      {isError ? <div className={styles.errorText}>{errorText || `enter the ${label}`}</div> : null}
    </div>
  );
};

export default Input;
