import { array, date, number, object, string } from "yup";

export const EVENT_TYPES = [
  {
    name: "anti-war protest",
    className: "antiWar",
    value: "1",
    color: "#7ea2ff",
  },
  {
    name: "political demonstration",
    className: "politicalDemonstrations",
    value: "2",
    color: "#D73232",
  },
  {
    name: "mixed protest",
    className: "mixed",
    value: "3",
    color: "#ECD931",
  },
  {
    name: "support event",
    className: "supportEvent",
    value: "4",
    color: "#ffb67e",
  },
];

export const EVENT_TYPE_BY_VALUE = Object.assign({}, ...EVENT_TYPES.map((x) => ({ [x.value]: x })));

export const Event = object({
  name: string().trim().max(255).notRequired().default(""),
  country: string().trim().max(255).required(),
  city: string().trim().max(255).required(),
  street: string().trim().max(255).notRequired().default(""),
  org_name: string().trim().max(255).notRequired().default(""),
  url: string().trim().url().max(255).notRequired().default(""),
  note: string().trim().max(255).notRequired().default(""),
  type: string().oneOf(Object.keys(EVENT_TYPE_BY_VALUE)).required(),
  date_time: date().required(),
  latitude: number().required(),
  longitude: number().required(),
});

//includes all fields from Event + id
export const ExistingEvent = Event.concat(
  object({
    id: number().required(),
  }),
);

export const MapPointer = object({
  type: string().default("Feature").required(),
  properties: object({
    id: number().required(),
    pr_name: string().trim().max(255).notRequired().default(""),
    country: string().trim().max(255).required(),
    city: string().trim().max(255).required(),
    street: string().trim().max(255).notRequired(),
    org_name: string().trim().max(255).notRequired().default(""),
    date: date().required(),
    comments: string().trim().max(255).notRequired(),
    url: string().trim().url().max(255).notRequired(),
    pr_type: string().trim().required(),
    pr_type_name: string().trim().required(),
    pr_type_id: string().oneOf(Object.keys(EVENT_TYPE_BY_VALUE)).required(),
  }),
  geometry: object({
    type: string().default("Point"),
    coordinates: array().of(number()).length(2),
  }).required(),
});

export const eventToPointer = (e) => {
  console.error(e);
  const p = {
    type: "Feature",
    properties: {
      id: e.id,
      pr_name: e.name,
      country: e.country,
      city: e.city,
      street: e.street,
      org_name: e.org_name,
      date: e.date_time,
      comments: e.note,
      url: e.url,
      pr_type: EVENT_TYPE_BY_VALUE[e.type].className,
      pr_type_name: EVENT_TYPE_BY_VALUE[e.type].name,
      pr_type_id: e.type,
    },
    geometry: { coordinates: [e.latitude, e.longitude] },
  };

  console.error(p);
  return MapPointer.cast(p);
};

export const MapPointerCollection = object({
  type: string().default("FeatureCollection"),
  name: string(),
  crs: object({
    type: string(),
    properties: object({ name: string() }),
  }).default({
    type: "name",
    properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
  }),
  features: array().of(MapPointer),
});
