import { useState } from "react";
import Checkbox from "../Checkbox";
import styles from "./style.module.scss";

const Legend = ({ eventTypes, onChange, checkedValues }) => {
  const [isClosed, setIsClosed] = useState(true);

  const onCheckboxChecked = (e) => {
    const isChecked = e.target.checked;
    let checked = new Map(checkedValues);
    if (isChecked) {
      checked.set(e.target.value, true);
    } else {
      checked.delete(e.target.value);
    }
    if (onChange) onChange(checked);
  };

  const onClickClose = () => setIsClosed(true);
  const onClickQuestionMark = () => {
    if (isClosed) setIsClosed(false);
  };

  return (
    <div
      className={`${styles.legend} ${isClosed ? styles.closed : ""}`}
      onClick={onClickQuestionMark}
    >
      <div className={styles.container}>
        <button className={styles.close} onClick={onClickClose}></button>
        {eventTypes.map((eventType) => (
          <Checkbox
            key={eventType.name}
            value={eventType.value}
            name="eventType"
            label={eventType.name}
            className={eventType.className}
            onChange={onCheckboxChecked}
            defaultChecked={checkedValues.get(eventType.value)}
          />
        ))}

        <div className={styles.bottomCheckbox}>
          {/* <Checkbox
                        value="showMy"
                        name="eventType"
                        label="show my"
                        onChange={onCheckboxChecked}
                    /> */}

          <Checkbox
            value="showPast"
            name="eventType"
            label="show past"
            className="showPast"
            onChange={onCheckboxChecked}
            defaultChecked={checkedValues.get("showPast")}
          />
        </div>
      </div>
    </div>
  );
};

export default Legend;

