import styles from "./style.module.scss";

const Checkbox = ({ value, name, label, className, onChange, defaultChecked }) => {
  return (
    <div className={styles.wrapper}>
      <label>
        <input
          className={styles.checkbox}
          type="checkbox"
          onChange={onChange}
          value={value}
          name={name}
          defaultChecked={defaultChecked}
        />

        <span className={`${styles.label} ${styles[className]}`}>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
