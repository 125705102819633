import { useEffect, useState, createContext } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import AddEventForm from "./components/AddEventForm";
import Legend from "./components/Legend";
import Header from "./components/Header";
import MarkdownTab from "./components/MarkdownTab";
import { EVENT_TYPES } from "./common/types";
import styles from "./style.module.scss";
import MapRef from "./MapRef";
import { getLocation } from "./api/geocoder";

export const MenuContext = createContext({
  isMenuOpen: false,
  toggleMenu: () => {},
});

function App() {
  // Search
  const [, setSearch] = useState("");

  // Map
  const location = useLocation();
  const [isMenuOpen, setMenuState] = useState(false);

  // Chosen protest type
  const [currentEventType, setCurrentEventType] = useState(null);

  // Current form address
  const [currentAddress, setCurrentAddress] = useState(null);
  const [currentCoordinates, setcurrentCoordinates] = useState(null);

  useEffect(() => {
    if (currentAddress) {
      const { country, city, street } = currentAddress;
      getLocation({ country, city, street }).then((res) => {
        if (res[0]) {
          setcurrentCoordinates({ lat: res[0].lat, lon: res[0].lon });
        }
      });
    } else {
      setcurrentCoordinates(null);
    }
  }, [currentAddress]);

  // Legend checkbox filters
  const [legendCheckbox, setLegendCheckbox] = useState(
    new Map(
      EVENT_TYPES.map((eventType) => {
        return [eventType.value, true];
      }).concat([["showPast", true]]),
    ),
  );

  const onChangeLegendCheckbox = (val) => {
    setLegendCheckbox(val);
  };

  /* useEffect(() => {
    window.addEventListener("map:test", (e, data) => {
      console.log(e, data);
    });
  }, []); */

  useEffect(() => {
    setMenuState(false);
  }, [location]);

  return (
    <div className="App">
      <MenuContext.Provider value={{ isMenuOpen, setMenuState }}>
        <Header onChange={setSearch} />
        <main className={styles.main}>
          <Routes>
            <Route path="/">
              <Route path="about" />
              <Route path="faq" />
            </Route>
          </Routes>

          <aside className={styles.aside}>
            <AddEventForm
              eventTypes={EVENT_TYPES}
              onSearchUpdate={setCurrentAddress}
              currentCoordinates={currentCoordinates}
              onRadioButtonUpdate={setCurrentEventType}
            />
            <Legend
              eventTypes={EVENT_TYPES}
              onChange={onChangeLegendCheckbox}
              checkedValues={legendCheckbox}
            />
          </aside>

          <aside className={styles.asideRight}>
            {location.pathname === "/faq" ? <MarkdownTab data="faq" /> : null}
            {location.pathname === "/about" ? <MarkdownTab data="about" /> : null}
          </aside>
          <MapRef
            filters={legendCheckbox}
            currentAddress={currentAddress}
            currentCoordinates={currentCoordinates}
            currentEventType={currentEventType}
          />
        </main>
      </MenuContext.Provider>
    </div>
  );
}

export default App;
