import { array, date, number, object, string } from "yup";
import {
  Event,
  ExistingEvent,
  EVENT_TYPE_BY_VALUE,
  MapPointer,
  MapPointerCollection,
} from "../common/types";

//***** REQUEST-RESPONSE SCHEMA ******//

export const CreateEventRequest = Event;

export const CreateEventResponse = object({
  id: number().required(),
});

export const GetEventRequest = object({
  id: number().required(),
});

export const GetEventResponse = object({
  event: ExistingEvent,
  pointer: MapPointer,
});

export const FindEventsRequest = object({
  country: string().trim().max(255).notRequired(),
  from_date: date().notRequired(),
  to_date: date().notRequired(),
  org_name: string().trim().max(255).notRequired(),
  city: string().trim().max(255).notRequired(),
  types: array()
    .of(string().oneOf(Object.keys(EVENT_TYPE_BY_VALUE)))
    .notRequired()
});

export const FindEventsResponse = object({
  events: array().of(ExistingEvent),
  pointers: MapPointerCollection,
});
