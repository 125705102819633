import {
  MapContainer,
  TileLayer,
  Popup,
  CircleMarker,
  Tooltip,
} from "react-leaflet";
import { React, useState, useRef, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import CurrentLocation from "./components/CurrentLocation";
import LocationMarker from "./components/LocationMarker";
import { findEvents } from "../src/api/events";
import { EVENT_TYPE_BY_VALUE } from "./common/types";

const MapRef = ({
  filters,
  currentAddress,
  currentCoordinates,
  currentEventType,
}) => {
  // activeEvent to show the popup when user clicks on marker
  const [activeEvent, setActiveEvent] = useState(null);
  const [events, setEvents] = useState([]);
  /* const map = useMap();
  const zoomToPopup = function (coords) {
    map.flyTo(coords, map.getZoom());
  }; */
  const dateComparison = function (dateString) {
    const date = new Date();
    const dSDate = new Date(dateString);

    return date > dSDate;
  };

  let mapRef = useRef();

   useEffect(() => {
    findEvents().then((response) => {
      setEvents(response.pointers);
    });
  }, []); 

  return (
    <MapContainer
      center={[54, 16]}
      zoom={5}
      zoomControl={false}
      minZoom={2}
      scrollWheelZoom={true}
      style={{
        width: "100vw",
        height: "calc(100vh - 50px)",
        position: "relative",
      }}
      id="mapDiv"
      ref={mapRef}
    >
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      ></TileLayer>

      <LocationMarker />
      {currentCoordinates ? (
        <CurrentLocation
          address={currentAddress}
          coordinates={currentCoordinates}
          currentEventType={currentEventType}
        />
      ) : null}

      {events.features?.map((eachData) => {
        if (!filters.get(eachData.properties.pr_type_id)) {
          return null;
        }
        // test this shit
        if (
          !filters.get("showPast") &&
          dateComparison(eachData.properties.date)
        ) {
          return null;
        }
        // end of test
        const identifyColor = dateComparison(eachData.properties.date)
          ? "grey"
          : EVENT_TYPE_BY_VALUE[eachData.properties.pr_type_id].color;

        return (
          <CircleMarker
            pathOptions={{
              radius: 9,
              fillColor: identifyColor,
              color: identifyColor,
              weight: 1,
              opacity: 1,
              fillOpacity: 0.8,
            }}
            key={eachData.properties.id}
            center={{
              lat: eachData.geometry?.coordinates[0],
              lng: eachData.geometry?.coordinates[1],
            }}
            eventHandlers={{
              click: () => {
                setActiveEvent(eachData);
              },
            }}
          >
            <Popup
              onClose={() => {
                setActiveEvent(null);
              }}
           
            >
              <div className="eventCard">
                <h2>{eachData.properties.pr_name}</h2>

                <div className="flex-container">
                  <div className="column">
                    {/*<span className="popover-suffix popover-suffix-up">
                      When?
                    </span>*/}
                    <span className="popover-stat">
                      {new Date(eachData.properties.date).toDateString()}
                    </span>
                  </div>

                  <div className="column">
                    {/*<span className="popover-suffix popover-suffix-up">
                      Where?
                    </span>*/}
                    <span className="popover-stat">
                      {eachData.properties.street}
                    </span>

                    {eachData.properties.city}
                    <br />
                    {eachData.properties.country}
                  </div>
                </div>
                {
                  <div className="flex-container">
                    <div className="column">
                      <span className="popover-suffix popover-suffix-up">
                        Organisator
                      </span>
                      <span className="popover-stat">
                        {eachData.properties.org_name}
                      </span>
                    </div>
                    <div className="column">
                      <span className="popover-suffix popover-suffix-up">
                        Join the event
                      </span>
                      <span className="popover-stat">
                        {eachData.properties.url}
                      </span>
                    </div>
                  </div>
                }
                {
                  <div className="flex-container">
                    <div className="column">
                      <span className="popover-suffix popover-suffix-up">
                        Extra information
                      </span>
                      <span className="popover-stat">
                        {eachData.properties.comments?.charAt(0).toUpperCase()}
                        {eachData.properties.comments?.slice(1)}
                      </span>
                    </div>
                  </div>
                }
              </div>
            </Popup>
            {/*   <Tooltip>
              <div>
                <h1>{eachData.properties.pr_name}</h1>
                <p>
                  {eachData.properties.pr_type_name.charAt(0).toUpperCase()}
                  {eachData.properties.pr_type_name.slice(1)} event
                </p>
                <p>Date: {eachData.properties.date.toString()}</p>
              </div>
            </Tooltip> */}
          </CircleMarker>
        );
      })}
    </MapContainer>
  );
};

export default MapRef;
