const baseUrl = "https://nominatim.openstreetmap.org/search";

export async function getLocation({ country, city, street }) {
  // https://nominatim.org/release-docs/develop/api/Search/#parameters
  const params = new URLSearchParams({
    format: "json",
    q: [country, city, street].filter((e) => e).join(", "),
  }).toString();

  try {
    // https://nominatim.org/release-docs/develop/api/Output/#json
    const response = await fetch(`${baseUrl}?${params}`);

    const result = await response.json();
    console.debug("OSM Geocoder response", { result });
    return result;
  } catch (e) {
    console.error("Error requesting from geocoder", e);
    throw e;
  }
}
