import { useMap, CircleMarker } from "react-leaflet";
import { React, useEffect } from "react";
import { EVENT_TYPE_BY_VALUE } from "../common/types";

function CurrentLocation({ address, coordinates, currentEventType }) {
  const map = useMap();

  const protestColor = currentEventType
    ? EVENT_TYPE_BY_VALUE[currentEventType].color
    : "white";

  useEffect(() => {
    if (address && (address.country || address.city || address.street)) {
      const { country, city, street } = address;
      map.flyTo(
        [coordinates.lat, coordinates.lon],
        country ? (street ? (city ? 15 : 12) : 7) : map.getZoom()
      );
      coordinates = null;
    }
  }, [address, map, coordinates]);

  if (!address) {
    return null;
  }

  return (
    <CircleMarker
      center={{ lat: coordinates.lat, lon: coordinates.lon }}
      pathOptions={{
        radius: 9,
        fillColor: protestColor,
        color: protestColor,
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      }}
    ></CircleMarker>
  );
}
export default CurrentLocation;
