import { useRef, useEffect } from "react";
import styles from "./style.module.scss";

export default function Confirmation({ onClose }) {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [buttonRef]);

  // TODO: proper css
  return (
    <div
      style={{
        padding: 20,
        marginTop: 10,
        position: "absolute",
        width: "100%",
        background: "white",
        borderRadius: 16,
      }}
    >
      <h2>Thank you!</h2>
      <p style={{ fontSize: 16 }}>
        You event was successfully submitted and will be added to the map after
        it's reviewed by our moderators.
      </p>
      <button
        style={{ width: "100%" }}
        className={styles.submit}
        onClick={onClose}
        ref={buttonRef}
      >
        Close
      </button>
    </div>
  );
}
