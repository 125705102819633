import Select from 'react-select';
import Input from '../Input';
import { DayPicker } from 'react-day-picker';
import { useCallback, useMemo, useState } from 'react';
import { format, sub, add, isToday, compareAsc } from 'date-fns';
import styles from './style.module.scss';

const Calendar = ({ onDateChange, onTimeChange }) => {
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const [hour, setHour] = useState('');
    const [isShowCalendar, setIsShowCalendar] = useState(true);

    const triggerSetSelectDay = (date) => {
        if (!date) return
        setSelectedDay(date);
        onDateChange(date);
    };

    const triggerSetHour = (date) => {
        if (!date) return
        setHour(date);
        onTimeChange(date);
    };

    const onHourChange = (e) => {
        triggerSetHour(e.target.value)
    }

    const onSelectDay = (date) => {
        triggerSetSelectDay(date);
        setIsShowCalendar(false);
    };

    const prevMonth = () => {
        const dt = sub(selectedDay, { months: 1 });
        setMonth(dt);
        triggerSetSelectDay(dt);
    };

    const nextMonth = () => {
        const dt = add(selectedDay, { months: 1 });
        setMonth(dt);
        triggerSetSelectDay(dt);
    };

    const dataText = useMemo(() => {
        return format(selectedDay, 'MMMM, d');
    }, [selectedDay]);

    const weekdayNameFormatter = useCallback((day, options) => {
        return format(day, 'ccc', options);
    }, []);

    const times = [
        '0:00',
        '1:00',
        '2:00',
        '3:00',
        '4:00',
        '5:00',
        '6:00',
        '7:00',
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
        '24:00',
    ];

    const isDisabled = (hour) => {
        if (!isToday(selectedDay)) return false
        
        const now = new Date();
        const [nowHour] = hour.split(':');
        now.setHours(nowHour);
        return compareAsc(new Date(), now) === 1;
    };

    const options = times.map((time) => {
        return {
            value: time,
            label: time,
        };
    });

    const selectStyle = {
        container: (provider) => {
            return {
                ...provider,
                marginTop: 10,
            };
        },
        control: (provider) => {
            return {
                ...provider,
                borderColor: '#2c2c2c',
                textAlign: 'center',
                '&:hover': {
                    borderColor: '#2c2c2c',
                },
                boxShadow: 'none',
            };
        },
        indicatorSeparator: (provider) => {
            return {
                ...provider,
                display: 'none',
            };
        },
        option: (provider, state) => {
            return {
                ...provider,
                backgroundColor: state.isSelected ? '#000' : 'transparent',
                '&:active': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                },
                '&:hover': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                },
                '&:selected': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                },
            };
        },
    };

    const DropdownIndicator = () => (
        <div className={`${styles.arrow} ${styles.down} ${styles.selectArrow}`}></div>
    );
    // const onClickHour = (hour) => {
    //     triggerSetHour(hour);
    // };

    const onChangeSelect = (option) => {
        triggerSetHour(option.value);
    };

    const onDateClick = (e) => {
        if (isShowCalendar) return;
        setIsShowCalendar(true);
    };

    return (
        <>
            <div className={styles.calendarDate}>
                <Input
                    onClick={onDateClick}
                    label="event date"
                    required
                    value={dataText}
                    disabled
                    inputClass={styles.calendarInput}
                    labelClass={styles.calendarLabel}
                />
                <div
                    onClick={prevMonth}
                    className={`${styles.nav} ${styles.arrow} ${styles.left}`}
                ></div>
                <div
                    onClick={nextMonth}
                    className={`${styles.nav} ${styles.arrow} ${styles.right}`}
                ></div>
            </div>
            {isShowCalendar ? (
                <DayPicker
                    mode="single"
                    selected={selectedDay}
                    onSelect={onSelectDay}
                    month={month}
                    onMonthChange={setMonth}
                    disableNavigation
                    weekStartsOn={1}
                    formatters={{
                        formatWeekdayName: weekdayNameFormatter,
                    }}
                />
            ) : (
                   <>
                    <div className={styles.selectContainer}>
                        <Select
                            options={options}
                            styles={selectStyle}
                            isSearchable={false}
                            placeholder="qw5"
                            value={{ label: hour, value: hour }}
                            components={{
                                DropdownIndicator,
                            }}
                            onChange={onChangeSelect}
                        />
                        <div className={styles.selectLabel}>event start</div>
                    </div>

                    <div className={styles.times}>
                        {times.map((time) => {
                            return (
                                <label className={styles.time} key={time}>
                                    <input
                                        type="radio"
                                        name="time"
                                        value={time}
                                        className={styles.timeRadio}
                                        disabled={isDisabled(time)}
                                        checked={hour === time}
                                        onChange={onHourChange}
                                    />
                                    <div className={styles.timeLabel}>
                                        {time}
                                    </div>
                                </label>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};

export default Calendar;
