import { useMap, Marker, Popup, CircleMarker } from "react-leaflet";
import { React, useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";

function LocationMarker() {
  const [position, setPosition] = useState(null);
  const [bbox, setBbox] = useState([]);

  const map = useMap();

  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, 15);
      const radius = e.accuracy;
      /* const circle = L.circle(e.latlng, radius);
      circle.addTo(map); */
      setBbox(e.bounds.toBBoxString().split(","));
    });
  }, [map]);

  return position === null ? null : (
    <CircleMarker center={position}>
      <Popup>
        You are here. <br />
      </Popup>
    </CircleMarker>
  );
}

export default LocationMarker;
