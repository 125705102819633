import APIClient from "./__apiClient";
import {
  CreateEventRequest,
  CreateEventResponse,
  FindEventsRequest,
  FindEventsResponse,
  GetEventRequest,
  GetEventResponse,
} from "../api/__schemas";

/**
 * Create a new event.
 * For types and default values of parameters please refer to CreateEventRequest schema.
 * @param {*} country
 * @param {*} city
 * @param {*} street
 * @param {*} link
 * @param {*} eventName
 * @param {*} organisationName
 * @param {*} eventType
 * @param {*} comments
 * @param {*} eventDate
 * @param {*} eventTime
 * @returns CreateEventResponse, containing id of newly created event.
 */
export async function createEvent({
  country,
  city,
  street,
  link,
  eventName,
  organisationName,
  eventType,
  comments,
  eventDate,
  eventTime,
  latitude,
  longitude,
}) {
  let dateTime = new Date(eventDate);
  if (eventTime) {
    dateTime = new Date(dateTime.setHours(eventTime.split(":")[0]));
    dateTime = new Date(dateTime.setMinutes(eventTime.split(":")[1]));
  }

  const event = {
    country: country,
    city: city,
    street: street,
    url: link,
    name: eventName,
    org_name: organisationName,
    type: eventType,
    note: comments,
    date_time: dateTime,
    latitude: latitude,
    longitude: longitude,
  };

  return CreateEventRequest.validate(event)
    .then((requestBody) => APIClient.postRequest("events/create", requestBody))
    .then((responseBody) => CreateEventResponse.cast(responseBody.body))
    .catch((error) => error.message);
}

/**
 * Get event by its id.
 * @param {int} id of the event
 * @returns event (refer to GetEventResponse type)
 */
export async function getEvent(id) {
  return GetEventRequest.validate({ id: id })
    .then((requestBody) => APIClient.postRequest("events/get", requestBody))
    .then((responseBody) => GetEventResponse.cast(responseBody.body))
    .catch((error) => error.message);
}

/**
 * Find all published events matching the query.
 * @param {json object} query   Query matching FindEventsRequest schema.
 * If query is not present this function will return all published events.
 * @returns array of matching events.
 *
 * Examples:
 * 1. Find all events:
 *      findEvents().then(console.log);
 * 2. Find events in the given country (exact case-insensitive match)
 *     findEvents({country: "abcd"}).then(console.log)
 * 3. Find all past events:
 *      findEvents({date_end: now()})
 * 4. Find all events of the given type in the given city
 *      findEvents({city: "abcd", type: 3}).
 */
export async function findEvents(query) {
  return (
    query
      ? FindEventsRequest.validate(query)
      : FindEventsRequest.validate(FindEventsRequest.default)
  )
    .then((requestBody) => APIClient.postRequest("events/find", requestBody))
    .then((responseBody) => FindEventsResponse.cast(responseBody.body))
    .catch((error) => error.message);
}
