import { useState, useEffect } from "react";
import "react-day-picker/dist/style.css";
import styles from "./style.module.scss";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const MarkdownTab = ({ data }) => {
  const [content, setContent] = useState("");

  // TODO: proper language switcher
  const intl = useIntl();
  const locale = ["en", "ru"].find((e) => e === intl.locale) || "en";
  const path = `text/${locale}/${data}.md`;

  // TODO: get the markdown to only load once
  useEffect(() => {
    fetch(path)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);

  return content.length ? (
    <div className={styles.container}>
      <Link to="/">
        <button className={styles.close} />
      </Link>
      <ReactMarkdown
        className={styles.markdownTab}
        children={content}
        remarkPlugins={[gfm]}
      />
    </div>
  ) : null;
};

export default MarkdownTab;
