import styles from "./style.module.scss";

const Radio = ({ value, name, label, className, onChange }) => {
  return (
    <div className={styles.wrapper}>
      <label>
        <input
          type="radio"
          onChange={onChange}
          value={value}
          name={name}
          className={styles.radio}
        />
        <div className={`${styles.label} ${styles[className]}`}>{label}</div>
      </label>
    </div>
  );
};

export default Radio;
