export default class APIClient {
  
  static postRequest(url, requestBody) {
    return fetch("/api/" + url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }).then(this.handleResponse);
  }

  static async handleResponse(response) {
    let responseBody = await response.json();
    if (!response.ok) {
      const error = responseBody.error || response.status;
      return Promise.reject(error);
    } else {
      return responseBody;
    }
  }
}
