import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import { MenuContext } from '../../App.js';

const contactEmail = 'united.future.now@protonmail.com';
const Header = ({ onChange, menuState = false }) => {
    const [search, setSearch] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const { isMenuOpen, setMenuState } = useContext(MenuContext);

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen)
    }

    const onSearchChange = (e) => {
        const val = e.target.value;
        setSearch(val);
        if (onChange) onChange(val);
    };

    return (
        <header className={styles.header}>
            <div className={styles.logoContainer}>
                <Link to="/" className={styles.logo}></Link>
            </div>

            {/* TODO: Раскоменитровать, что бы вернуть строку поиска в хэдер */}
            {/* <button className={styles.searchBtn} onClick={toggleSearch}></button>
            <div className={`${styles.search} ${isSearchOpen ? styles.open : ''}`}>
                <input
                    placeholder="search here"
                    value={search}
                    onChange={onSearchChange}
                />
            </div> */}

            <div className={styles.burgerMenu} onClick={() => setMenuState(!isMenuOpen)}>
                <div className={styles.burgerMenuItem}></div>
                <div className={styles.burgerMenuItem}></div>
                <div className={styles.burgerMenuItem}></div>
            </div>
            <ul className={`${styles.menu} ${isMenuOpen ? styles.open : ''}`}>
                <li><a href={`mailto:${contactEmail}`}>contact</a></li>
                <li>
                    <Link to="/about">about</Link>
                </li>
                <li>
                    <Link to="/faq">faq</Link>
                </li>
            </ul>
            <div className={styles.close} onClick={() => setMenuState(false)}></div>
        </header>
    );
};

export default Header;
